import DataTables from "datatables.net-dt";

let created_datatables = {};

document.addEventListener('turbolinks:load', function() {
    console.log("datatables", document.body.dataset.datatables);
    if (document.body.dataset.datatables !== "{}") {
        const tables = JSON.parse(document.body.dataset.datatables);
        for (const id in tables) {
            if (created_datatables[id]) {
                console.log("datatables", "destroy", id);
                created_datatables[id].destroy();
            }
            console.log("datatables", "create", id);
            created_datatables[id] = new DataTables('#' + id, {
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/2.1.7/i18n/pl.json',
                },
                ...tables[id]
            });
        }
    }
});
